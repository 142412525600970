const format_timestamp = function(stamp, full=false){
  if(isNaN(stamp) || stamp == 0){return "-"; }
  if(stamp<1000000000000){ stamp *= 1000; }  
  let d = new Date(stamp);
  let ret = d.getFullYear()+"-"+(""+(1+d.getMonth())).padStart(2,"0")+"-"+(""+d.getDate()).padStart(2,"0");
  if(full){
  	ret += " "+(""+d.getHours()).padStart(2,"0")+":"+(""+d.getMinutes()).padStart(2,"0")+":"+(""+d.getSeconds()).padStart(2,"0");
  }
  return ret
}

export default format_timestamp