<style>
.el-icon-info{color:#999; font-size:32px; margin-right:5px;}
.line{display:flex; flex-wrap: nowrap;}

.keyword{width:200px;}
.red{color:red;}
.block td{background-color:#e6e6e6 !important}
.right{text-align: right;}
.el-checkbox--medium{padding:5px 10px !important; height: 31px !important}
.role1edit .adminedit{height: auto; margin: 15vh auto !important;}
.role1edit .label{font-size:15px; color:#909399;}
.role1edit .label:nth-of-type(n+2){margin-top:20px;}

.role1edit .role{width:100%; border-bottom:dashed 1px #ddd;}
.role1edit .role:nth-of-type(n+2){margin-top:10px; }
.role1edit .role_name{color:#666; font-size:14px;}
.role1edit .role_area{font-size:14px;}
.role1edit .el-icon-delete{color:#890720; cursor:pointer; font-size:18px;}

.role1edit .el-checkbox{margin-right:0; width:25%;}
.role1edit .el-autocomplete{width:100%;}
.role1 .role_dialog{ margin-top:20vh !important;}
</style>
<template>
    <div class="component role1">
        <!-- 编辑框 -->
        <el-dialog width="400px" custom-class="role1edit" :title="data_for_edit.id>0 ? '编辑账号' : '添加账号'" v-model="show_edit" :close-on-click-modal="false">
            <el-row class="label">
                <el-col :span="6" class="red">登录账号</el-col>
                <el-col :span="18">
                    <el-input v-model="data_for_edit.username" placeholder="用于登录后台，3-20位" autocomplete="off" size="small" clearable></el-input>
                </el-col>
            </el-row>
            <el-row class="label">
                <el-col :span="6"  :class="{ red : data_for_edit.id == 0 }">登录密码</el-col>
                <el-col :span="18">
                    <el-input v-model="data_for_edit.passwd" :placeholder="data_for_edit.id > 0 ? '6位以上，若不修改请留空' : '6位以上'" autocomplete="off"  size="small"></el-input>
                </el-col>
            </el-row>
            <el-row class="label">
                <el-col :span="6" class="red">显示称呼</el-col>
                <el-col :span="18">
                    <el-input v-model="data_for_edit.truename" placeholder="例如：姓名、机构名" autocomplete="off" size="small" clearable></el-input>
                </el-col>
            </el-row>
            <el-row class="label">
                <el-col :span="6">联系电话</el-col>
                <el-col :span="18">
                    <el-input v-model="data_for_edit.phone" placeholder="输入联系电话" autocomplete="off"  size="small"></el-input>
                </el-col>
            </el-row>
            <template #footer>
                <el-button type="primary" @click="click_edit_done">确 定</el-button>
                <el-button @click="show_edit = false">关 闭</el-button>
            </template>
        </el-dialog>
        <!-- 编辑框结束 -->

        <div class="title-panel">
            <el-row>
                <el-col :span="18">
                    <i class="el-icon-info"></i>
                    <div class="inline"><div class="label">物业账号</div><div class="descript">管理本小区物业人员账号</div></div>
                </el-col>
                <el-col :span="6" class="btn-col">
                    <el-button type="success" plain icon="el-icon-circle-plus-outline" @click="click_add">添加账号</el-button>
                    <el-button type="primary" plain icon="el-icon-refresh" @click="load_list">刷新数据</el-button>
                </el-col>
            </el-row>
        </div>
        <!-- 筛选条 -->
        <el-row style="display:flex; align-items: center; background: #f9f9f9; padding:5px 5px;">
            <el-select  style="width:100px;" class="role" v-model="block" placeholder="账号状态" clearable size="small">
                <el-option label="正常" value="0"></el-option>
                <el-option label="停用" value="1"></el-option>
            </el-select>
            <div>&nbsp;</div>
            <el-input style="width:250px" class="keyword" size="small" v-model="key" placeholder="搜索账号、称呼、联系电话" clearable></el-input> 
            <div style="flex-grow:1">&nbsp;</div>
            <el-pagination background layout="total, prev, pager, next" :page-size="size" :current-page="page" :total="total" @current-change="page_change"></el-pagination>
        </el-row>
        <!-- 筛选条结束 -->
        <div class="table-box" id="table-box">
            <el-table :max-height="table_height" :data="list" :stripe="true" border :row-class-name="tableRowClassName">
                <el-table-column label="添加时间" width="180" align="center">
                    <template #default="scope">
                        <span v-html="format_timestamp(scope.row.created_at, true)"></span>
                    </template>
                </el-table-column>
                <el-table-column prop="lastactive" label="最近活动" width="200" align="center">
                    <template #default="scope">
                        <span v-html="format_timestamp(scope.row.lastactive, true)"></span>
                    </template>
                </el-table-column>
                <el-table-column prop="username" label="登录账号" width="150" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column prop="truename" label="显示称呼" width="150" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column prop="phone" label="联系电话" width="150" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column fixed="right" min-width="150" label="操作">
                    <template #default="scope">
                        <el-button v-if="scope.row.block==0" @click="click_block(scope.$index)" type="text" size="small">停用</el-button>
                        <el-button v-else @click="click_block(scope.$index)" type="text" size="small">启用</el-button>
                        <el-button @click="click_edit(scope.row)" type="text" size="small">编辑</el-button>
                        <el-button @click="click_del(scope.$index)" type="text" size="small">删除</el-button>
                        
                    </template>
                </el-table-column>
            </el-table>      
        </div>
    </div>
    
</template>
<script>
import {inject} from 'vue'
import format_timestamp from "../plugins/utils.js"

export default {
    name:'busis',
    setup:function(){
        const gd = inject('gd');
        return {gd};
    },//setup
    data:function(){
        return {
            block:'',
            key:'',
            page:1,
            size:10,
            total:0,
            list:[],
            show_edit:false,
            data_for_edit:{},
            table_height:800,
        }
    },//data end
    mounted:function(){
        this.$nextTick(()=>{ this.table_height = document.getElementById('table-box').offsetHeight -12; });
        this.init();
    },//mounted end
    watch:{
        page:function(){ this.load_list(); },//page
        block:function(){ this.page=1; this.load_list(); },//block
        key:function(){ this.page=1; this.load_list(); },//key
    },
    methods:{
        init: function(){
            this.load_list();
        },//init
        load_list:function(){
            this.axios.post("/api/user/role1",{page:this.page, size:this.size, block:this.block, key:this.key}).then(ret=>{
                if(!ret.ok){this.$message.error(ret.msg); return;}
                this.total = ret.data.total;
                this.list = ret.data.list;
            });
        },//load_list
        tableRowClassName:function({row}){
            return row.block == 1 ? 'block' : '';
        },//tableRowClassName
        format_timestamp:function(stamp){
            return format_timestamp(stamp, true);
        },//format_timestamp
        page_change:function(page){ this.page = page; },//page_change
        click_block:function(idx){
            this.axios.post("/api/user/role1_block",{id:this.list[idx].id}).then(ret=>{
                if(!ret.ok){this.$message.error(ret.msg); return;}
                this.$message.success('操作成功');
                this.list[idx].block = 1 - this.list[idx].block;
            });
        },//click_top
        click_del:function(idx){
            this.$confirm('此操作将永久删除该账号, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.axios.post("/api/user/role1_del",{id:this.list[idx].id}).then(ret=>{
                    if(!ret.ok){this.$message.error(ret.msg); return;}
                    this.$message.success('操作成功');
                    this.list.splice(idx,1);
                }).catch((err) => { console.error(err); });
            }).catch((e) => { console.error(e); });
        },//click_del end
        click_add:function(){
            this.show_edit = true;
            this.data_for_edit = {
                id:0,
                username:'',
                passwd:'',
                truename:'',
                phone:'',
            };
        },//click_add
        click_edit:function(row){
            //console.log(row);
            this.show_edit = true;
            this.data_for_edit = JSON.parse(JSON.stringify(row));
            this.data_for_edit.passwd = '';
        },//click_edit end
        click_edit_done:function(){
            if(this.data_for_edit.username.length < 3 || this.data_for_edit.username.length > 20 ){ this.$message.error('请输入管理员账号，3-20位'); return; }
            if(this.data_for_edit.passwd.length > 0 && this.data_for_edit.passwd.length < 6){ this.$message.error('登录密码需6位以上'); return; }
            if(this.data_for_edit.truename == ''){ this.$message.error('请输入显示称呼'); return; }
            this.axios.post("/api/user/role1_edit",this.data_for_edit).then(ret=>{
                if(!ret.ok){this.$message.error(ret.msg); return;}
                this.$message.success('操作成功');
                this.show_edit = false;
                this.load_list();
            });
        },//click_edit_done
    },//methods end
};
</script>